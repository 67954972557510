export const tabContentData = (t) => ([
    {
        tabId: "1",
        title: "Essay",
        description: t("assignments_tab_desc1"),
        href: `/view-sample/essay-sample.pdf`,
        icon: "/assignment/essay.svg"
    },
    {
        tabId: "2",
        title: "Research Paper",
        description: t("assignments_tab_desc2"),
        href: `/view-sample/research-paper-sample.pdf`,
        icon: "/assignment/researchPaper.svg"
    },
    {
        tabId: "3",
        title: "Case Study",
        description: t("assignments_tab_desc3"),
        href: `/view-sample/case-study-sample.pdf`,
        icon: "/assignment/caseStudy.svg"
    },
    {
        tabId: "4",
        title: "Course Work",
        description: t("assignments_tab_desc4"),
        href: `/view-sample/coursework-sample.pdf`,
        icon: "/assignment/courseWork.svg"
    },
    {
        tabId: "5",
        title: "Assignment",
        description: t("assignments_tab_desc5"),
        href: `/view-sample/assignment-sample.pdf`,
        icon: "/assignment/assignment.svg"
    },
    {
        tabId: "6",
        title: "",
        description: "",
        href: ``,
        icon: "/assignment/revamp-to-sample.png"
    }
]);