import React, { useState } from "react";
// scss
import styles from "./assignment.module.scss";
import Link from "next/link";
import { tabContentData } from "./data";
import { useTranslation } from 'next-i18next';
import Image from "next/image";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

function Assignment() {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const { t } = useTranslation("common");
  const tabData = tabContentData(t);

  return <>
    <section className={styles.assignment}>
      <Container>
        <div className="text-center">
          <h2 className="section-title">{t("assignments_title")}</h2>
          <p className="desc">{t("assignments_desc")}</p>
        </div>
      </Container>
      <div className={styles.assignmentDetails}>
        <Container>
          <Tab.Container defaultActiveKey={activeTab}>
            <Row>
              <Col lg={7}>
                <Nav variant="tabs" className={`d-flex flex-wrap justify-content-end ${styles.services}`}>
                  {
                    tabData.map(({ tabId, title, icon }) => (
                      <Nav.Item className={styles.servicesItem} key={tabId}>
                        <Nav.Link
                         className={`${styles.servicesTab} ${activeTab === tabId ? styles.active : ''}`}
                         onClick={() => toggle(tabId)}
                         eventKey={tabId}>
                         <Image src={icon} alt="Types of Assignments" width={50} height={50} />
                         {tabId !== "6" && <span>{title}</span>}
                        </Nav.Link>
                      </Nav.Item>
                    ))
                  }
                </Nav>
              </Col>
              <Col lg={5}>
                <Tab.Content className={styles.tabContent}>
                  {
                    tabData.map(({ tabId, title, description, href }) => (
                      tabId === activeTab && <Tab.Pane key={tabId} eventKey={tabId}
                                                       className={`${styles.tabPaneBox} ${tabId === "6" ? styles.tabPaneMore : ""}`}>
                       {tabId !== "6" ?
                        <>
                          <h3 className={styles.title}>{title}</h3>
                          <p>{description}</p>
                        </>
                        :
                        <div className={styles.exploreAllSample}>Explore all <span>500+</span> Samples for Free</div>}
                        <Link
                          href={tabId !== "6" ? href : "/examples" }
                          locale={false}
                          prefetch={false}
                          passHref
                          className={`secondary-btn ${styles.btn} btn w-100`}
                          target="_blank">

                          {tabId !== "6" ? "View sample" : "View all samples"}

                        </Link>
                      </Tab.Pane>
                    ))
                  }
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    </section>
  </>;
}

export default Assignment;